/** @jsx jsx */
import { jsx, Box, Themed } from 'theme-ui';
import { Container } from '@sprinklr/shared-lib';
import { Avatar } from '@sprinklr/shared-lib';
import { useContainerPadding } from '@sprinklr/shared-lib/hooks';
import { ContainerPadding } from '@sprinklr/shared-lib/@types/types';
import { useI18next, useTranslation } from 'gatsby-plugin-react-i18next';

const DirectorBoard: React.FC<{
  padding: ContainerPadding;
  pageContext: any;
  directorBoardPeople: any;
}> = ({ padding, directorBoardPeople }) => {
  const { t } = useTranslation();
  const header = t('Our Board of Directors');

  const totalPeople = directorBoardPeople.length;
  const [pt, pb] = useContainerPadding(padding);

  return (
    <Container containerSx={{ pt, pb }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Themed.h2
          sx={{
            textAlign: 'center',
            marginBottom: ['40px', '89px', '89px'],
          }}
        >
          {header}
        </Themed.h2>

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            maxWidth: [
              '100%',
              totalPeople >= 6 && totalPeople <= 9 ? '1060px' : '1325px',
              totalPeople >= 6 && totalPeople <= 9 ? '1088px' : '1360px',
            ],
            flexWrap: 'wrap',
          }}
        >
          {directorBoardPeople.map(person => (
            <Avatar
              key={person.name}
              titleRichText={person.titleRichText}
              name={person.name}
              image={person.image?.gatsbyImageData}
            />
          ))}
        </Box>
      </Box>
    </Container>
  );
};

export default DirectorBoard;
